<template>
  <div>
    <customer-details>
      <template v-slot:btnPrope>
        <v-btn
          class="t-btn--prm mr-5 ml-2"
          :disabled="!checkPerUser"
        >
          {{ $t('task_29786.keep') }}
        </v-btn>
      </template>
      <div class="mx-7 mt-7" style="width: 1000px">
        <div class="d-flex justify-space-between mb-2">
          <h2 class="text--title">会員請求作成履歴</h2>
          <v-btn
            :disabled="!checkPerUser"
            class="t-btn--prm"
            style="width: 98px; height: 32px"
            @click="visible = 1"
          >
            <!-- :disabled="!checkPerUser" -->
            <span class="text-12px"
              ><i class="fas fa-plus"></i>&nbsp;請求登録</span
            >
          </v-btn>
        </div>
        <Table
          :attr="{
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id'
          }"
          ref="table"
          :itemsPerPage="50"
          :itemsPerPageOptions="[ 50, 100, 200, 500]"
          :headers="headers"
          :items="getClientChargeList"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            <div class="text-overflow">
              <span v-if="item.createdAt">{{
                formatDate(item.createdAt)
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.chargeDate`]="{ item }">
            <div class="text-overflow">
              <span v-if="item.chargeDate">{{
                formatDate(item.chargeDate)
              }}</span>
            </div>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <div class="text-overflow">
              <span v-if="item.price">{{ formatCurrency(item.price) }}</span>
            </div>
          </template>
          <template v-slot:[`item.paymentMethod`]="{ item }">
            <div class="text-overflow">
              <span v-if="item.paymentMethod === 'TOP'">TOPカード</span>
              <span v-else-if="item.paymentMethod === 'GMO'">登録カード（GMO）</span>
              <span v-else-if="item.paymentMethod === 'INVOICE'">請求書</span>
              <span v-else>その他</span>
            </div>
          </template>
          <template v-slot:[`item.isError`]="{ item }">
            <div class="text-overflow">
              <span v-if="item.isError === true && item.paymentMethod !== 'TOP'">失敗</span>
              <span v-else-if="item.isError === false && item.paymentMethod !== 'TOP'">成功</span>
              <span v-else-if="item.paymentMethod === 'TOP'"></span>
            </div>
          </template>
          <template v-slot:[`item.showMember`]="{ item }">
            <v-menu bottom offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="item.showMember === false ? '#9E9E9E' : '#32B679'"
                  class="btnStatus"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="!checkPerUser"
                  ><span>{{ item.showMember ? '表示' : '非表示' }}</span>
                </v-btn>
              </template>
              <v-list>
                <div class="px-2 div-select-dialog">
                  <v-select
                    dense
                    class="my-1"
                    @change="updateClientChargeList(item)"
                    v-model="item.showMember"
                    item-value="showMemberStatus"
                    item-text="name"
                    :items="getShowMembers"
                    :disabled="!checkPerUser"
                  >
                  </v-select>
                </div>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:[`item.receipt`]="{ item }">
            <i
              v-if="item.receipt && item.receipt.pdfFileUrl" 
              @click="printPDF(item.receipt.pdfFileUrl)"
              class="fas fa-print icon"
            />
            <span v-else />
          </template>
        </Table>
        <!-- Dialog -->
        <billing-registration
          :visible="visible === 1"
          @close="visible = -1"
        ></billing-registration>
        <!-- PDF -->
        <!-- Receipt annual fee PDF -->
        <!-- <div id="ReceiptAnnualFeePDF" class="hidden-pdf">
          <div class="border-pdf">
            <div class="text-no text-8px">領収書No. {{ ReceiptNo }}</div>
            <div class="d-flex justify-space-between mt-10">
              <div>
                <p class="title-receipt">領収書</p>
                <div>
                  <table class="text-receipt">
                    <tr>
                      <td>入金確認日</td>
                      <td>：</td>
                      <td>2021年5月1日</td>
                    </tr>
                    <tr>
                      <td>振込完了日</td>
                      <td>：</td>
                      <td>2021年5月1日</td>
                    </tr>
                    <tr>
                      <td>会員番号</td>
                      <td>：</td>
                      <td>0000000001</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div style="text-align: right" class="text-receipt">
                <p>お客様の詳細</p>
                <p>東急太郎 様</p>
                <p>〒150-0002</p>
                <p>東京都渋谷区渋谷1-16-14</p>
                <p>渋谷地下鉄ビル</p>
                <p>03-0000-0000</p>
              </div>
            </div>
            <div>
              <p class="mt-16 title-div-second">
                年会費／メンバーシップフィーお支払明細
              </p>
              <div class="border-custom">
                <div>
                  <p class="text-title mt-5">年会費</p>
                  <p class="text-value">{{ AnnualFee }}</p>
                </div>
                <div class="my-8">
                  <p class="text-title">管理費</p>
                  <p class="text-value">{{ ManagementFee }}</p>
                </div>
                <div>
                  <p class="text-title">温泉維持費</p>
                  <p class="text-value">{{ HotSpringMaintenanceFee }}</p>
                </div>
                <div
                  style="width: 50%"
                  class="mt-8 mb-4 d-flex justify-space-between"
                >
                  <div>
                    <p class="text-title">お支払合計金額(税込)</p>
                    <p class="text-value">{{ TotalPaymentAmount }}</p>
                  </div>
                  <div>
                    <p class="text-title">内消費税</p>
                    <p class="text-value">
                      {{ InternalConsumptionTaxAnnualFee }}
                    </p>
                  </div>
                </div>
                <div>
                  <p class="text-title">期間</p>
                  <p class="text-value">{{ period }}</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- End Receipt annual fee PDF -->

        <!-- Receipt Annual Book PDF -->
        <!-- <div id="ReceiptAnnualBookPDF" class="hidden-pdf">
          <div class="border-pdf">
            <div class="text-8px text-no">領収書No. {{ ReceiptNo }}</div>
            <div class="d-flex justify-space-between mt-10">
              <div>
                <p class="title-receipt">領収書</p>
                <div>
                  <table class="text-receipt">
                    <tr>
                      <td>入金確認日</td>
                      <td>：</td>
                      <td>2021年5月1日</td>
                    </tr>
                    <tr>
                      <td>振込完了日</td>
                      <td>：</td>
                      <td>2021年5月1日</td>
                    </tr>
                    <tr>
                      <td>会員番号</td>
                      <td>：</td>
                      <td>0000000001</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div style="text-align: right" class="text-receipt">
                <p>お客様の詳細</p>
                <p>東急太郎 様</p>
                <p>〒150-0002</p>
                <p>東京都渋谷区渋谷1-16-14</p>
                <p>渋谷地下鉄ビル</p>
                <p>03-0000-0000</p>
              </div>
            </div>
            <div>
              <p class="title-div-second mt-16">
                ポイント追加明細
              </p>
              <div class="border-custom">
                <div
                  style="width: 50%"
                  class="mt-8 mb-4 d-flex justify-space-between"
                >
                  <div>
                    <p class="text-title">シェアリングポイント</p>
                    <p class="text-value">{{ SharingPoint }}</p>
                  </div>
                  <div>
                    <p class="text-title">内消費税</p>
                    <p class="text-value">
                      {{ InternalConsumptionTaxAnnualBook }}
                    </p>
                  </div>
                </div>
                <div>
                  <p class="text-title">プライムウィーク</p>
                  <p class="text-value">{{ PrimeWeek }}</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- End Receipt Annual Book PDF -->
      </div>
    </customer-details>
  </div>
</template>
<script>
import BillingRegistration from './BillingRegistration.vue';
import CustomerDetails from '../CustomerDetails.vue';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import { currency2 } from '@/utils/filters.js';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Table from '@/components/Table/index.vue';

export default {
  components: {
    BillingRegistration,
    CustomerDetails,
    Table
  },
  name: 'membership-billing-history',
  data() {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      PurchaseEvaluation: '契約完了',

      visible: -1,
      headers: [
        {
          text: '請求作成日',
          value: 'createdAt',
          sortable: true,
        },
        {
          text: '請求日',
          value: 'chargeDate',
          sortable: false,
        },
        {
          text: '内容',
          value: 'clientChargeType.name',
          sortable: false,
        },
        {
          text: '金額',
          value: 'price',
          sortable: false,
        },
        {
          text: '請求方法',
          value: 'paymentMethod',
          sortable: false,
        },
        {
          text: 'ステータス',
          value: 'isError',
          sortable: false,
        },
        {
          text: '会員ページ表示',
          value: 'showMember',
          sortable: false,
        },
        {
          text: '領収書',
          value: 'receipt',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['getClientChargeList', 'getShowMembers']),
  },
  methods: {
    ...mapActions(['actionGetClientChargeList', 'actionUpdateClientChange'
    // , 'actionReceipt'
    ]),
    ...mapMutations([
      'setClientChargeList',
      'setAlertSuccess',
      'setAlertError',
    ]),
    printPDF(pdfFileUrl) {
      // this.actionReceipt(itemId).then(pdfFileUrl => {
      //   if (pdfFileUrl) {
          window.open(pdfFileUrl);
        // } else {
        //   this.setAlertError('領収書PDFが存在しない');
        // }
      // });
      // var el = '';
      // if (item.id % 2 === 0) {
      //   el = document.getElementById('ReceiptAnnualFeePDF');
      // } else {
      //   el = document.getElementById('ReceiptAnnualBookPDF');
      // }
      // el.style.display = 'block';
      // html2canvas(el).then(canvas => {
      //   const imgData = canvas.toDataURL('image/png');
      //   const imgWidth = 190;
      //   const pageHeight = 290;
      //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
      //   let heightLeft = imgHeight;
      //   // eslint-disable-next-line
      //   const pdfFile = new jsPDF('pt', 'mm');
      //   let position = 0;
      //   pdfFile.addImage(
      //     imgData,
      //     'PNG',
      //     10,
      //     position,
      //     imgWidth,
      //     imgHeight + 15,
      //   );
      //   heightLeft -= pageHeight;
      //   while (heightLeft >= 0) {
      //     position = heightLeft - imgHeight;
      //     pdfFile.addPage();
      //     pdfFile.addImage(
      //       imgData,
      //       'PNG',
      //       10,
      //       position,
      //       imgWidth,
      //       imgHeight + 15,
      //     );
      //     heightLeft -= pageHeight;
      //   }
      //   el.style.display = 'none';
      //   window.open(pdfFile.output('bloburl'));
      // });
    },
    formatDate(date) {
      return moment(date).format('YYYY/MM/DD');
    },
    formatCurrency(amount) {
      return currency2(amount);
    },
    updateClientChargeList (item) {
      const finishData = {
        id: item.id,
        showMember: item.showMember
      }
      this.actionUpdateClientChange(finishData)
    }
  },
  mounted() {
    this.actionGetClientChargeList();
  },
};
</script>

<style lang="scss" scoped>
.btnStatus {
  width: 70px;
  color: white;
  border-radius: 8px;
  text-align: right;
}
.icon {
  background-color: #13ace0;
  color: white;
  width: 33px;
  height: 33px;
  border-radius: 33px;
  padding-top: 6px;
  font-size: 20px;
  cursor: pointer;
}
.div-select-dialog {
  height: 58px;
  width: 200px;
  overflow: hidden;
  .select-dialog {
    position: absolute;
    top: 0px;
    width: 173px;
  }
}
.custom-pdf {
  display: none;
}
::v-deep {
  .table-custom {
    tr {
      th {
        font-size: 14px !important;
      }
      td {
        font-size: 16px !important;
      }
    }
    .v-data-footer {
      color: #000000 !important;
      .v-icon__svg {
        color: #333333;
      }
      &__pagination {
        display: block !important;
      }
      &__select {
        font-size: 11px !important;
      }
      .v-select__selection {
        font-size: 11px !important;
      }
      button.v-btn--disabled {
        svg {
          color: #bebebe;
        }
      }
      .v-data-footer__pagination{
        display: none !important;
      }
    }
  }
}

// pdf
.text-title {
  color: #7693a0;
  font-size: 14px !important;
}
.text-value {
  color: #000000;
  font-size: 14px !important;
  font-weight: bold;
  line-height: 0.5px;
}
.text-receipt {
  color: #000000;
  font-weight: bold;
}
.hidden-pdf {
  position: absolute;
  width: 60%;
  top: -100000px;
  left: -100000px;
  display: none;
}
.border-pdf {
  border: 1px solid #bdbdbd;
  margin-top: 30px;
  padding: 50px 100px;
  width: 80%;
}
.text-no {
  font-weight: bold;
  text-align: right;
}
.title-receipt {
  font-size: 20px;
  color: #000000;
  margin: 0;
}
.title-div-second {
  font-size: 20px;
  color: #000000;
}
.border-custom {
  border-top: 1px solid #bdbdbd;
}
.title-header {
  font-size: 20px;
  color: #0c6786;
}
</style>
