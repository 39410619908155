var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('customer-details',{scopedSlots:_vm._u([{key:"btnPrope",fn:function(){return [_c('v-btn',{staticClass:"t-btn--prm mr-5 ml-2",attrs:{"disabled":!_vm.checkPerUser}},[_vm._v(" "+_vm._s(_vm.$t('task_29786.keep'))+" ")])]},proxy:true}])},[_c('div',{staticClass:"mx-7 mt-7",staticStyle:{"width":"1000px"}},[_c('div',{staticClass:"d-flex justify-space-between mb-2"},[_c('h2',{staticClass:"text--title"},[_vm._v("会員請求作成履歴")]),_c('v-btn',{staticClass:"t-btn--prm",staticStyle:{"width":"98px","height":"32px"},attrs:{"disabled":!_vm.checkPerUser},on:{"click":function($event){_vm.visible = 1}}},[_c('span',{staticClass:"text-12px"},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" 請求登録")])])],1),_c('Table',{ref:"table",attrs:{"attr":{
          dense: true,
          'no-data-text': _vm.$t('rules.noData'),
          'item-key': 'id'
        },"itemsPerPage":50,"itemsPerPageOptions":[ 50, 100, 200, 500],"headers":_vm.headers,"items":_vm.getClientChargeList},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-overflow"},[(item.createdAt)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))]):_vm._e()])]}},{key:"item.chargeDate",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-overflow"},[(item.chargeDate)?_c('span',[_vm._v(_vm._s(_vm.formatDate(item.chargeDate)))]):_vm._e()])]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-overflow"},[(item.price)?_c('span',[_vm._v(_vm._s(_vm.formatCurrency(item.price)))]):_vm._e()])]}},{key:"item.paymentMethod",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-overflow"},[(item.paymentMethod === 'TOP')?_c('span',[_vm._v("TOPカード")]):(item.paymentMethod === 'GMO')?_c('span',[_vm._v("登録カード（GMO）")]):(item.paymentMethod === 'INVOICE')?_c('span',[_vm._v("請求書")]):_c('span',[_vm._v("その他")])])]}},{key:"item.isError",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"text-overflow"},[(item.isError === true && item.paymentMethod !== 'TOP')?_c('span',[_vm._v("失敗")]):(item.isError === false && item.paymentMethod !== 'TOP')?_c('span',[_vm._v("成功")]):(item.paymentMethod === 'TOP')?_c('span'):_vm._e()])]}},{key:"item.showMember",fn:function(ref){
        var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btnStatus",attrs:{"color":item.showMember === false ? '#9E9E9E' : '#32B679',"disabled":!_vm.checkPerUser}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(item.showMember ? '表示' : '非表示'))])])]}}],null,true)},[_c('v-list',[_c('div',{staticClass:"px-2 div-select-dialog"},[_c('v-select',{staticClass:"my-1",attrs:{"dense":"","item-value":"showMemberStatus","item-text":"name","items":_vm.getShowMembers,"disabled":!_vm.checkPerUser},on:{"change":function($event){return _vm.updateClientChargeList(item)}},model:{value:(item.showMember),callback:function ($$v) {_vm.$set(item, "showMember", $$v)},expression:"item.showMember"}})],1)])],1)]}},{key:"item.receipt",fn:function(ref){
        var item = ref.item;
return [(item.receipt && item.receipt.pdfFileUrl)?_c('i',{staticClass:"fas fa-print icon",on:{"click":function($event){return _vm.printPDF(item.receipt.pdfFileUrl)}}}):_c('span')]}}],null,true)}),_c('billing-registration',{attrs:{"visible":_vm.visible === 1},on:{"close":function($event){_vm.visible = -1}}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }