<template>
  <main-layout>
    <v-dialog v-model="dialog">
      <div >
        <h1>請求登録</h1>
        <v-form ref="form" v-model="valid" lazy-validation>
          <div class="div-left mt-2">
            <h4>請求額</h4>
            <tvos-int-input
              jpy
              class="w-50"
              v-model="price"
              dense
              :rules="[$rules.required]"
              required
            />
          </div>
          <div class="div-left">
            <h4>内容</h4>
            <v-text-field
              v-model="note"
              dense
              :rules="[$rules.checkLenghInput(255)]"
            ></v-text-field>
          </div>
          <div class="div-right">
            <h4>請求方法</h4>
            <v-select
              dense
              class="w-50 select-style"
              :items="paymentOptions"
              item-value="id"
              item-text="name"
              v-model="dataPaymentMethodsSelected"
              :rules="[$rules.required]"
              required
              no-data-text="GMO・TOPカード情報が未登録です"
            ></v-select>
          </div>
          <div class="d-flex justify-end">
            <v-btn
              class="t-btn--red-dark btn-custom mr-2"
              width="36"
              @click="closeDialog"
            >
              閉じる
            </v-btn>
            <v-btn class="t-btn--prm btn-custom" width="68" @click="save">
              保存
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-dialog>
  </main-layout>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import MainLayout from '@/layouts/MainLayout';
import gql from 'graphql-tag';

export default {
  components: { MainLayout },
  name: 'ContractSpecialDialog',
  data() {
    return {
      price: null,
      note: '',
      valid: true,
      client: null,
    };
  },
  props: {
    visible: Boolean,
  },
  computed: {
    ...mapGetters(['getPaymentMethods', 'getPaymentMethodsSelected']),
    dataPaymentMethodsSelected: {
      get() {
        return this.getPaymentMethodsSelected;
      },
      set(value) {
        this.setPaymentMethodsSelected(value);
      },
    },
    dateRangeText: {
      get() {
        return this.dates.join(' ~ ');
      },
      set() {
        this.dates.join(' ~ ');
      },
    },
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    clientId() {
      return parseInt(this.$router.currentRoute.params.id)
    },
    paymentOptions () {
      return this.getPaymentMethods.filter(({id}) => {
        // id is either GMO or TOP
        if (id === 'TOP' && !this.client?.topCardNumber) {
          return false
        }
        if (id === 'GMO' && !this.client?.registeredCC) {
          return false
        }
        return true
      })
    }
  },
  watch: {
    paymentOptions() {
      // select the default.
      this.dataPaymentMethodsSelected = this.paymentOptions[0].id
    }
  },
  methods: {
    ...mapActions(['actionCreateClientChange']),
    ...mapMutations([
      'setPaymentMethodsSelected',
      // Alert
      'setAlertSuccess',
      'setAlertError',
    ]),
    save() {
      if (this.price && this.getPaymentMethodsSelected) {
        const finishData = {
          price: this.price,
          note: this.note,
          paymentMethod: this.getPaymentMethodsSelected,
          clientId: this.clientId,
        };
        this.actionCreateClientChange(finishData).then(result => {
          this.$store.dispatch('actionGetClientChargeList')
          this.setAlertSuccess('請求登録に成功しました');
          this.closeDialog();
        });
      } else {
        this.$refs.form.validate();
      }
    },
    closeDialog() {
      this.price = null;
      this.note = null;
      this.setPaymentMethodsSelected(null);
      this.$refs.form.reset();
      this.$emit('close');
    },
  },
  apollo: {
    client: {
      query: gql`query getClientForCreateCharge ($id: Int!) { clientBasicInfo(id: $id) { id topCardNumber registeredCC { cardNo } } }`,
      fetchPolicy: 'no-cache',
      variables() { return { id: this.clientId }},
      update: data => data.clientBasicInfo
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: #212121 !important;
  font-size: 28px !important;
  font-weight: bold !important;
}
h4 {
  color: #424242 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}
.v-btn__content {
  font-size: 14px !important;
}
::v-deep {
  .v-dialog {
    width: 404px;
    min-height: 280px;
    background-color: white;
    border-radius: 9px;
    padding: 16px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
.select-style{
  ::v-deep{
    .v-icon__svg {
      color:#333333;
    }
  }
}
// label
.title-field {
  color: #424242;
  font-size: 8px;
}
// btn
.btn-custom {
  height: 32px !important;
  font-size: 14px !important;
}
</style>